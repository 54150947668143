.meetup-list {
	float: right;
	width: 100%;
	padding-left: 25px;

	@include min-screen(768px) {
		width: 325px;	
	}

	@include min-screen(1024px) {
		width: 300px;
	}

	@include min-screen(1280px) {
		width: 350px;
	}

	h2 {

		@include min-screen(768px) {
			float: right;
		}

		@include min-screen(1024px) {
			float: left;
		}
	}

	.events {

		@include min-screen(768px) {
			float: right;
		}

		@include min-screen(1024px) {
			float: left;
		}
	}

	.will-fade-in {
		transition: opacity 1s, transform 0.5s;
	    opacity: 0;
	    transform: translate3d(0, 40px, 0);
	}
}
