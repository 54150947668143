.events {
	width:  100%;
	margin-bottom: 20px;
	position: relative;
	transition: 0.3s ease;

	@include min-screen(768px) {
		width: 300px;
	}

	@include min-screen(1024px) {
		width: 264px;
	}

	@include min-screen(1280px) {
		width: 325px;
	}


	img {
		margin-bottom: 10px;
	}

	&__date {
		display: inline-block;
		background: $white;
		padding: 10px 15px 10px 10px;
		margin-bottom: 15px;
		font: 700 18px/24px $main-font;

		@include min-screen(1024px) {
			font: 700 16px/24px $main-font;
		}

		@include min-screen(1280px) {
			font: 700 18px/24px $main-font;
		}

		.fa {
			margin-right: 10px;
		}
	}

	&__location {
		display: inline-block;
		background: $white;
		padding: 10px 15px 10px 10px;
		font: 700 18px/24px $main-font;

		@include min-screen(1024px) {
			font: 700 16px/24px $main-font;
		}

		@include min-screen(1280px) {
			font: 700 18px/24px $main-font;
		}

		.fa {
			margin-right: 10px;
		}
	}

	&__website {
		color: $white;
		background: $blue;
		padding: 10px 15px 10px 15px;
		display: inline-block;
		font: 700 18px/24px $main-font;
		box-shadow: 0 4px 6px rgba(0,0,0,.2), 0 1px 3px rgba(0,0,0,.08);

		@include min-screen(768px) {
			background: $orange;
		}

		&:hover {
			transform: translateY(-2px);
			backface-visibility: hidden;
			box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 3px 6px rgba(0,0,0,.08);
		}

		.fa {
			margin-right: 10px;
		}
	}
}

.image-container {
	position:relative;
	overflow: hidden; 
	display: inline-block;

	img {
		max-width: 100%; 
		height: auto;
		display: block;
	}

	a {
		position:absolute;
		color: #fff;
		top: 0;
		left: 0;
		padding: 0;
		z-index:2;
		background: rgba(255, 255, 255, 0.77);
		opacity:0;
		width: 100%;
		height: 96.5%;
		text-align:center;
		font-size:40px;  
		transition: opacity .5s ease-in-out;
		z-index:1;
	}

	&:hover a {
		opacity:1;
	}

	.popup h2 {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		color: $black;
		font: 600 24px/24px $main-font;
		text-transform: uppercase;
	}  
}

.will-fade-in {
	transition: opacity 1s, transform 0.5s;
    opacity: 0;
    transform: translate3d(0, 70px, 0);
    backface-visibility: hidden;
}