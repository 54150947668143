.event-single {

	&__image {
		margin-bottom: 30px;

		@include min-screen(1024px) {
			float: left;
			width: 50%;
			margin: 0 30px 60px 0;
		}
	}

	&__title {
		font: 600 24px/28px $main-font;
		text-transform: uppercase;
		color: $white;
		letter-spacing: 1px;
	}

	&__text {
		overflow: hidden;
		margin: 30px 0;

		p {
			color: $white;
			font: 400 20px/32px $main-font;
		}
	}
}