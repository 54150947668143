a {
	text-decoration: none;
	transition: $transition;
	color: $white;

	&:hover, &:focus, &:active {
		transition: $transition;
		text-decoration: none;
		color: $blue;
	}
}