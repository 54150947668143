.about {

	&__title {
		font: 600 30px/40px $main-font;
		color: $white;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-align: center;
	}

	&__sub-title {
		text-align: center;
		color: $white;
		font: 400 24px/30px $main-font;
	}

	&__content {
		border: 1px solid rgba(255,255,255,0.3);
		background: rgba(255,255,255,0.1);
		padding: 30px;
		margin: 60px 0;

		p {
			color: $white;
			font: 400 20px/30px $main-font;
		}

		blockquote {
			border-left: 5px solid $orange;
			padding-left: 20px;
			color: $white;
			font: 600 24px/32px $main-font;
		}

		h2 {
			margin: 20px 0 10px;
		}
	}
}