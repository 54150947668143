.footer {
	padding: 60px 0;
	clear: both;
	overflow: hidden;
	margin-top: 60px;
	border-top: 1px solid rgba(255,255,255,0.3);

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		@include min-screen(1024px) {
			width: 500px;
			float: left;
		}

		li {
			display: block;
			float: left;
			margin-right: 15px;

			a {
				display: block;
				color: $white;
				text-transform: uppercase;
				font: 400 16px/24px $main-font;

				&:hover {
					color: $black;
				}
			}
		}
	}

	&__copyright {
		display: block;
		clear: both;
		text-transform: uppercase;
		color: $white;
		font: 400 16px/17px $main-font;
		

		@include min-screen(1024px) {
			float: right;
			margin-top: -13px;
			clear: none;
		}

		img {
			margin: 10px 0 -8px 10px;
		}
	}
}