.button {
	background: $orange;
	color: $white;
	padding: 5px 20px;
	display: inline-block;
	text-transform: uppercase;
	font: 700 20px/24px $main-font;
	letter-spacing: 1px;
	transition: 0.5s;
	box-shadow: 0 4px 6px rgba(0,0,0,.2), 0 1px 3px rgba(0,0,0,.08);
	white-space: nowrap;

	&:hover, &:focus, &:active {
		transform: translateY(-2px);
		backface-visibility: hidden;
		box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 3px 6px rgba(0,0,0,.08);
	}
}