.event-list {
	width: 100%;
	float: left;
	
	margin-bottom: 60px;

	@include min-screen(768px) {
		width: 325px;
		border-right: 1px solid rgba(255,255,255,0.3);
	}

	@include min-screen(1024px) {
		width: 600px;
	}

	@include min-screen(1280px) {
		width: 730px;
	}

	.events {
		float: left;

		@include min-screen(1024px) {
			margin-right: 35px;
		}
	}

	.will-fade-in {

			&:nth-child(2n) {
	    	transition: opacity 1s, transform 0.5s;
		    opacity: 0;
		    transform: translate3d(0, 50px, 0);
	    }
	}
}

.event-type-inactive {
	opacity: 0.5;
}