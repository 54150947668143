.wrapper {
	width: 90%;
	margin: 0 auto;
	position: relative;
	overflow: hidden;

	@include min-screen(768px) {
		max-width: 650px;
	}

	@include min-screen(1024px) {
		max-width: 900px;
	}

	@include min-screen(1280px) {
		max-width: 1100px;
	}
}