.header {
	margin: 60px 0;
	padding-bottom: 50px;
	clear: both;
	display: block;
	overflow: hidden;
	border-bottom: 1px solid rgba(255,255,255,0.3);

	@include min-screen(1024px) {
		padding-bottom: 20px;
	}
 
	&__logo {
		background: url('../img/logo.svg') no-repeat;
		display: block;
		text-indent: -9999px;
		float: left;
		margin-right: 60px;
		background-size: 330px;

		@include min-screen(1024px) {
			height: 124px;
    		width: 300px;
		}

		@include min-screen(1280px) {
			height: 130px;
    		width: 328px;
		}
		
		a {
			display: block;
			height: 130px;
			width: 320px;
		}
	}

	&__text {
		float: right;
		margin-top: -4px;

		@include min-screen(1024px) {
			width: 540px;
		}

		@include min-screen(1280px) {
			width: 700px;
		}

		p {
			color: $white;

			@include min-screen(1024px) {
				font: 400 18px/24px $main-font;
			}
			
			@include min-screen(1280px) {
				font: 400 20px/28px $main-font;
			}
		}
	}
}

.arrow {
	position: absolute;
    top: 1150px;
    height: 20px;
    width: 20px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: block;
    background: red;
    z-index: 999;
    opacity: 1;
    transition: 0.3s opacity ease;

    &.hide {
    	opacity: 0;
    	transition: 0.3s opacity ease;
    }
}