.wpcf7-form {

	p {
		margin: 0;
	}

	input {
		padding: 10px 20px;
		border: 0;
		width: 100%;
		outline: 0;
		color: $black;
		backface-visibility: hidden;

		&:focus {
			box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 3px 6px rgba(0,0,0,.08);
			transform: translateY(-2px);
			transition: 0.3s;
			backface-visibility: hidden;
		}
	}

	textarea {
		width: 100%;
		padding: 20px;
		color: $black;
		border: 0;
		outline: 0;
		backface-visibility: hidden;

		&:focus {
			box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 3px 6px rgba(0,0,0,.08);
			transform: translateY(-2px);
			transition: 0.3s;
			backface-visibility: hidden;
		}
	}

	.file-upload {
		text-align: center;
		color: $white;
		padding: 20px 0;

		input {
			width: 252px;
			margin: 10px auto;
			line-height: 10px;
			color: $white;
		}
	}

	.wpcf7-submit {
		background: $blue;
		color: $white;
		padding: 10px 20px;
		width: 200px;
		margin: 0 auto;
		text-align: center;
		letter-spacing: 1px;
		display: block;
		font: 600 24px/34px $main-font;
		text-transform: uppercase;
		transition: 0.5s;
		box-shadow: 0 4px 6px rgba(0,0,0,.2), 0 1px 3px rgba(0,0,0,.08);

		&:hover, &:focus, &:active {
			transform: translateY(-2px);
			backface-visibility: hidden;
			box-shadow: 0 7px 14px rgba(0,0,0,.1), 0 3px 6px rgba(0,0,0,.08);
		}
	}
}